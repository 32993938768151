import styled, { css } from 'styled-components'

interface ButtonProps {
  outline: boolean
  bigger: boolean
  footer?: boolean
  responsive?: boolean
  whiteBorder?: boolean
  transparent?: boolean
  newsletter?: boolean
}

export const Button = styled.button<ButtonProps>`
  width: ${(props) => (props.bigger ? 208 : 150)};
  padding: 0 3.2rem;
  height: 4.8rem;
  color: ${(props) => (props.outline ? '#09C3F4' : '#fff')};
  border: 2px solid;
  border-color: ${(props) => (props.whiteBorder ? '#fff' : ' #09c3f4')};
  background: ${(props) => (props.outline ? '#fff' : '#09C3F4')};
  border-radius: 0.8rem;
  font-weight: 500;
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s all;

  ${(props) =>
    props.footer &&
    css`
      background: transparent;
      color: #fff;
      height: 5.6rem;
      width: 24rem;

      &:hover {
        background: #09c3f4;
        border-color: #09c3f4;
      }
    `}

  ${(props) =>
    props.newsletter &&
    css`
      background: rgba(30, 24, 83, 0.64);
      color: #fff;
      height: 5.6rem;
      width: 24rem;
      transition: 0.2s all;
      border: 0;

      &:hover {
        filter: brightness(1.3);
      }
    `}


  &:hover {
    background: ${(props) => props.outline && '#09C3F4'};
    color: ${(props) => props.outline && '#fff'};

    ${(props) =>
      props.bigger &&
      css`
        background: #08afdb;
        border-color: #08afdb;
      `}
  }
`
