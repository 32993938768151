import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import Image from 'next/image'
import Link from 'next/link'

import * as S from './styles'

interface BurgerProps {
  onClose: () => void
}

const BurgerMenu = (props: BurgerProps) => {
  const { t } = useTranslation('diff')
  const router = useRouter()

  return (
    <S.Wrapper>
      <S.Content>
        <S.BurgerHeader>
          <Link
            href={
              router.query && (router.query.slug || router.query.category)
                ? '/blog'
                : router.asPath
            }
            locale={router.locale === 'en-us' ? 'pt-br' : 'en-us'}
          >
            <S.Language>{t('menu-change-locale')}</S.Language>
          </Link>
          <S.CloseButton onClick={props.onClose}>
            <Image
              loading="eager"
              src="/icons/close.png"
              width={12}
              height={12}
              objectFit="contain"
              alt={t('i4cast-api-alt')}
              quality={100}
            />
          </S.CloseButton>
        </S.BurgerHeader>

        <Link href="/">
          <Image
            src="/white-logo.png"
            width={103.3}
            height={40}
            loading="eager"
            alt="i4sea"
            quality={100}
          />
        </Link>

        <S.List>
          <S.ListItem onClick={props.onClose}>
            <S.ListItem href="/tech" onClick={props.onClose}>
              {t('menu-technology')}
            </S.ListItem>
          </S.ListItem>
        </S.List>
        <S.List gap="2rem" marginTop="3rem">
          <S.ListItem
            href="/i4castproduct"
            onClick={props.onClose}
            size="1.2rem"
          >
            i4Cast
          </S.ListItem>
          <S.ListItem href="/i4castapi" onClick={props.onClose} size="1.2rem">
            API
          </S.ListItem>
          <S.ListItem href="/data" onClick={props.onClose} size="1.2rem">
            {t('historic-data-sub-title')}
          </S.ListItem>
        </S.List>
        <S.List>
          <S.ListItem href="/blog">Blog</S.ListItem>
        </S.List>
        <S.List marginTop="3rem">
          <S.ListItem href="/advantages" onClick={props.onClose}>
            {t('menu-advantage')}
          </S.ListItem>
        </S.List>
        <S.List marginTop="3rem">
          <S.ListItem href="/ourteam" onClick={props.onClose}>
            {t('menu-ourteam')}
          </S.ListItem>
        </S.List>
        <S.ButtonWrapper>
          <S.NavLogin>
            <S.Navigation
              onClick={props.onClose}
              rel="noopener"
              target="_blank"
              href="https://i4cast.i4sea.com/login"
            >
              Login
            </S.Navigation>
          </S.NavLogin>
        </S.ButtonWrapper>

        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdENmjKT7lUOj5I-Ydw94iAFXCnc7jJouBE0z9VBBP9XeOZ-A/viewform?embedded=true"
          target="_blank"
          rel="noreferrer"
        >
          <S.Button className="getdemo">{t('menu-get-trial')}</S.Button>
        </a>
      </S.Content>
    </S.Wrapper>
  )
}

export default BurgerMenu
