import styled, { css } from 'styled-components'

interface FooterProps {
  directory?: string
}

export const Wrapper = styled.footer`
  width: 100%;
  padding: 8rem 5rem;

  display: flex;
  justify-content: center;

  background-image: url('/footer-background.webp');
  background-size: cover;
  background-position: center bottom;

  @media (max-width: 720px) {
    height: 60rem;
  }
`

export const Container = styled.div`
  max-width: 1280px;
  width: 100%;

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
`

export const InputsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 1.6rem;
  mix-blend-mode: normal;
  padding: 3.2rem 7.2rem 4rem;

  @media (max-width: 720px) {
    padding: 2.4rem 4.8rem 4rem;
    margin-top: -3.6rem;
  }
`
export const Title = styled.strong`
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.4rem;
  color: #09c3f4;
  text-align: center;

  @media (max-width: 720px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`
export const Inputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 2.2rem;
`
export const Input = styled.input`
  width: 39.2rem;
  height: 5.6rem;
  background-color: rgba(255, 255, 255, 0.08);
  padding: 0 32px;
  border-radius: 0.8rem;
  margin-right: 1.6rem;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #fff;
  border: 0;

  &::placeholder {
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #fff;
  }
`

export const FooterItems = styled.div<FooterProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => (props.directory !== '/' ? 2.4 : 8.7)}rem;

  @media (max-width: 720px) {
    flex-direction: column;
    margin-top: 0;
  }
`

export const FooterMiddleSectionWrapper = styled.div<FooterProps>`
  ${(props) =>
    props.directory !== '/' &&
    css`
      flex-direction: column;
    `}
`

export const FooterMiddleSection = styled.ul`
  @media (max-width: 720px) {
    margin-top: 4rem;
    text-align: center;
  }
`
export const FooterMiddleSectionItems = styled.div``
export const FooterMiddleSectionItem = styled.a`
  font-size: 1.4rem;
  letter-spacing: 0.14rem;
  color: #fff;
  line-height: 1.7rem;
  cursor: pointer;

  & + a {
    margin-left: 3.2rem;
  }

  @media (max-width: 720px) {
    margin-top: 2rem;
    display: block;

    & + a {
      margin-left: 0rem;
    }
  }
`

export const Social = styled.a`
  cursor: pointer;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    margin: 6.4rem 0 0.8rem;
    align-items: center;
    justify-content: center;
    opacity: 1;
  }
`

export const FooterMiddleSectionDescription = styled.div`
  font-size: 1.2rem;
  letter-spacing: 0.12rem;
  color: rgba(255, 255, 255, 0.64);
  line-height: 1.4rem;
  text-align: center;
  margin-top: 0.8rem;

  @media (max-width: 720px) {
    display: none;
  }
`

export const MobileFooterMiddleSectionDescription = styled.div`
  font-size: 1.15rem;
  letter-spacing: 0.12rem;
  color: rgba(255, 255, 255, 0.64);
  line-height: 1.4rem;
  text-align: center;
  margin-top: 0.8rem;

  @media (min-width: 720px) {
    display: none;
  }
`
