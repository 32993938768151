import Image from 'next/image'
import { Button } from 'components/Button/styles'
import { useTranslation } from 'next-i18next'
import * as S from './styles'
import { useRouter } from 'next/router'

const Footer = () => {
  const { t } = useTranslation('diff')
  const router = useRouter()
  const { asPath } = router

  const formDemoLink =
    router.locale === 'en-us'
      ? 'https://meetings.hubspot.com/mariana-thevenin/agendamento-pelo-site'
      : 'https://meetings.hubspot.com/mariana-thevenin/agendamento-pelo-site'

  return (
    <S.Wrapper>
      <S.Container>
        {asPath === '/' && (
          <S.InputsContainer>
            <S.Title>{t('footer-title')}</S.Title>
            <S.Inputs>
              <a href={formDemoLink} target="_blank" rel="noreferrer">
                <Button footer outline={false} bigger={true}>
                  {t('footer-button')}
                </Button>
              </a>
            </S.Inputs>
          </S.InputsContainer>
        )}

        <S.FooterItems directory={asPath}>
          <Image
            src="/logo.png"
            width={103.3}
            height={40}
            loading="eager"
            alt="i4sea"
          />
          <S.FooterMiddleSectionWrapper directory={asPath}>
            <S.FooterMiddleSection>
              <S.FooterMiddleSectionItem href="/ourteam">
                {t('menu-ourteam')}
              </S.FooterMiddleSectionItem>
              <S.FooterMiddleSectionItem href="/tech">
                {t('menu-technology')}
              </S.FooterMiddleSectionItem>
              <S.FooterMiddleSectionItem href="/i4castproduct">
                i4cast
              </S.FooterMiddleSectionItem>
              <S.FooterMiddleSectionItem href="/advantages">
                {t('menu-advantage')}
              </S.FooterMiddleSectionItem>
            </S.FooterMiddleSection>
            <S.FooterMiddleSectionDescription>
              {t('copy')}
            </S.FooterMiddleSectionDescription>
          </S.FooterMiddleSectionWrapper>

          <S.Social
            href="https://www.linkedin.com/company/i4sea/"
            target="_blank"
          >
            <Image
              src="/icons/linkedin.png"
              width={36}
              height={36}
              loading="eager"
              alt="Linkedin i4sea"
            />
          </S.Social>
          <S.MobileFooterMiddleSectionDescription>
            {t('copy')}
          </S.MobileFooterMiddleSectionDescription>
        </S.FooterItems>
      </S.Container>
    </S.Wrapper>
  )
}
export default Footer
