import { ButtonHTMLAttributes, ReactNode } from 'react'
import * as S from './styles'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  outline?: boolean
  bigger?: boolean
  footer?: boolean
  whiteBorder?: boolean
  transparent?: boolean
  newsletter?: boolean
}

const Button = ({
  children,
  bigger = false,
  outline = false,
  footer = false,
  whiteBorder = false,
  transparent = false,
  newsletter = false,
  ...rest
}: ButtonProps) => (
  <S.Button
    footer={footer}
    bigger={bigger}
    outline={outline}
    whiteBorder={whiteBorder}
    transparent={transparent}
    newsletter={newsletter}
    {...rest}
  >
    {children}
  </S.Button>
)

export default Button
