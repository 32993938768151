import styled, { css } from 'styled-components'
import Image from 'next/image'

interface ColorBlack {
  colorBlack?: boolean
}

interface Hovered {
  hovered?: boolean
}

export const Wrapper = styled.menu`
  width: 100%;
  background: transparent;
  position: fixed;
  z-index: 2;
  transition: 0.35s all;

  &.active {
    box-shadow: 0px 8px 8px rgba(30, 24, 83, 0.16);
    background: #fff;
    color: #1e1853;
  }
`

export const Content = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.4rem 1rem;

  > a {
    cursor: pointer;
  }

  @media (max-width: 720px) {
    padding: 2.4rem 2.4rem;
  }
`

export const List = styled.ul`
  list-style: none;
  display: flex;
  gap: 4.8rem;
  margin-left: 15rem;

  @media (max-width: 720px) {
    display: none;
  }
`

export const Navigation = styled.a<ColorBlack>`
  cursor: pointer;
  text-decoration: none;
  font-size: 1.4rem;
  letter-spacing: 0.14rem;
  text-transform: uppercase;
  color: ${(props) => (props.colorBlack ? '#1E1853' : '#fff')};
  transition: 0.2s all;

  &.active {
    color: #1e1853;
  }

  &:hover {
    color: #09c3f4;
  }
`

export const ListItem = styled.a<ColorBlack>`
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.14rem;
  cursor: pointer;
  text-transform: uppercase;
  color: ${(props) => (props.colorBlack ? '#1E1853' : '#fff')};
  transition: 0.2s all;

  display: flex;
  align-items: center;

  &.active {
    color: #1e1853;

    &:hover {
      color: #09c3f4;
    }
  }

  &:nth-child(2) {
    position: relative;
    gap: 0.6rem;
    height: 3rem;
  }
`

export const ProductMenu = styled.div`
  position: absolute;
  top: 3rem;
  background: #fff;
  height: 23.2rem;
  width: 28.2rem;
  box-shadow: 0px 0px 16px rgba(30, 24, 83, 0.16);
  border-radius: 0.8rem;
  padding: 0.8rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 720px) {
    display: none;
  }
`

export const ProductMenuArrow = styled(Image)<Hovered>`
  transition: 0.4s all;

  ${(props) =>
    props.hovered &&
    css`
      transform: rotate(-180deg);
      transition: 0.4s all;
    `}

  @media (max-width: 720px) {
    display: none;
  }
`

export const ProductMenuItem = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.8rem;
  padding: 1.6rem 2.4rem;

  &:hover {
    background-color: #ebfafe;
  }
`

export const ProductMenuItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.6rem;
`

export const ProductMenuItemDescriptionTitle = styled.span`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.68rem;
  letter-spacing: 0.014rem;
  color: #09c3f4;
  text-transform: none;
`
export const ProductMenuItemDescriptionSubtitle = styled.span`
  font-weight: 400;
  font-size: 1rem;
  margin-top: 0.4rem;
  line-height: 1.4rem;
  letter-spacing: 0.01rem;
  color: #1e1853;

  text-transform: none;
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 3.2rem;

  @media (max-width: 720px) {
    display: flex;
    align-items: center;
  }
`

export const Button = styled.button`
  padding: 0 2rem;
  height: 4rem;
  letter-spacing: 0.14rem;
  background: linear-gradient(
    0deg,
    #0b63af 0.05%,
    #147cc0 18.93%,
    #1894d2 37.92%,
    #1fa6df 55.21%,
    #23b2e7 70.02%,
    #25b6ea 80.63%
  );
  border-radius: 0.8rem;
  border: 0;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  transition: 0.2s all;
  cursor: pointer;

  &:hover {
    background: linear-gradient(
      0deg,
      #095596 0.05%,
      #116ba6 18.93%,
      #1581b8 37.92%,
      #1b92c4 55.21%,
      #1f9fcf 70.02%,
      #21a3d1 80.63%
    );
  }
`

export const NavLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 720px) {
    display: none;
  }
`

export const Language = styled.button<ColorBlack>`
  margin-left: -2.4rem;
  padding: 0 2rem;
  height: 4rem;
  letter-spacing: 0.14rem;
  background: transparent;
  border-radius: 0.8rem;
  border: 1px solid;
  border-color: ${(props) =>
    props.colorBlack ? 'rgba(30, 24, 83, 0.16)' : 'rgba(255, 255, 255, 0.16)'};
  color: ${(props) => (props.colorBlack ? '#1E1853' : '#fff')};
  font-size: 1.4rem;
  font-weight: 500;
  transition: 0.2s all;
  cursor: pointer;

  @media (max-width: 720px) {
    display: none;
  }

  &:hover {
    filter: brightness(0.9);
  }
  &.active {
    color: #1e1853;
    border: 1px solid rgba(30, 24, 83, 0.08);
  }
`

export const BurgerMenuWrapper = styled.button`
  cursor: pointer;
  background: transparent;
  border: 0;

  @media (min-width: 720px) {
    display: none;
  }
`
