import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import BurgerMenu from 'components/BurgerMenu'
import * as S from './styles'

const Menu = () => {
  const { t } = useTranslation('diff')
  const router = useRouter()
  const [navbar, setNavbar] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isHovered, setHovered] = useState(false)

  const [getWidth, setGetWidth] = useState(0)

  const formDemoLink =
    'https://meetings.hubspot.com/mariana-thevenin/agendamento-pelo-site'

  useEffect(() => {
    const width = screen.width
    setGetWidth(width)
  }, [])

  const handleScroll = () => {
    if (window.scrollY >= 160) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  function handleImageBurguer(navbar: boolean) {
    if (router.asPath !== '/') {
      return '/icons/burger2.png'
    }

    if (navbar) {
      return '/icons/burger1.png'
    }

    return '/icons/burger.png'
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <>
      <S.Wrapper className={navbar ? 'active' : ''}>
        <S.Content>
          <a href={router.locale === 'pt-br' ? '/pt-br' : '/'}>
            <Image
              src="/logo.png"
              width={103.3}
              height={40}
              loading="eager"
              alt="i4sea"
            />
          </a>

          <S.List>
            <S.ListItem
              href={router.locale === 'pt-br' ? '/pt-br/tech' : '/tech'}
              colorBlack={router.asPath !== '/'}
              className={navbar ? 'active' : ''}
            >
              {t('menu-technology')}
            </S.ListItem>
            <S.ListItem
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              colorBlack={router.asPath !== '/'}
              className={navbar ? 'active' : ''}
              href="#product"
            >
              <S.ProductMenuArrow
                height={18}
                width={18}
                src="/icons/arrow.png"
                hovered={isHovered}
                alt={t('menu-product')}
              />
              {t('menu-product')}

              {isHovered && (
                <S.ProductMenu>
                  <a
                    href={
                      router.locale === 'pt-br'
                        ? '/pt-br/i4castproduct'
                        : '/i4castproduct'
                    }
                  >
                    <S.ProductMenuItem>
                      <Image
                        src="/icons/layers.png"
                        height={30}
                        width={30}
                        objectFit="contain"
                        alt="i4sea"
                      />
                      <S.ProductMenuItemDescription>
                        <S.ProductMenuItemDescriptionTitle>
                          i4cast
                        </S.ProductMenuItemDescriptionTitle>
                        <S.ProductMenuItemDescriptionSubtitle>
                          {t('menu-1')}
                        </S.ProductMenuItemDescriptionSubtitle>
                      </S.ProductMenuItemDescription>
                    </S.ProductMenuItem>
                  </a>

                  <a
                    href={
                      router.locale === 'pt-br'
                        ? '/pt-br/i4castapi'
                        : '/i4castapi'
                    }
                  >
                    <S.ProductMenuItem>
                      <Image
                        src="/icons/settings.png"
                        height={30}
                        width={30}
                        objectFit="contain"
                        alt="settings"
                      />
                      <S.ProductMenuItemDescription>
                        <S.ProductMenuItemDescriptionTitle>
                          API
                        </S.ProductMenuItemDescriptionTitle>
                        <S.ProductMenuItemDescriptionSubtitle>
                          {t('menu-2')}
                        </S.ProductMenuItemDescriptionSubtitle>
                      </S.ProductMenuItemDescription>
                    </S.ProductMenuItem>
                  </a>

                  <a href={router.locale === 'pt-br' ? '/pt-br/data' : '/data'}>
                    <S.ProductMenuItem>
                      <Image
                        src="/icons/calendar.png"
                        height={30}
                        width={30}
                        objectFit="contain"
                        alt="calendar"
                      />
                      <S.ProductMenuItemDescription>
                        <S.ProductMenuItemDescriptionTitle>
                          {t('historic-data-sub-title')}
                        </S.ProductMenuItemDescriptionTitle>
                        <S.ProductMenuItemDescriptionSubtitle>
                          {t('menu-3')}
                        </S.ProductMenuItemDescriptionSubtitle>
                      </S.ProductMenuItemDescription>
                    </S.ProductMenuItem>
                  </a>
                </S.ProductMenu>
              )}
            </S.ListItem>
            <S.ListItem
              href={router.locale === 'pt-br' ? '/pt-br/blog' : '/blog'}
              colorBlack={router.asPath !== '/'}
              className={navbar ? 'active' : ''}
            >
              Blog
            </S.ListItem>
            <S.ListItem
              href={
                router.locale === 'pt-br' ? '/pt-br/advantages' : '/advantages'
              }
              colorBlack={router.asPath !== '/'}
              className={navbar ? 'active' : ''}
            >
              {t('menu-advantage')}
            </S.ListItem>
            <S.ListItem
              href={router.locale === 'pt-br' ? '/pt-br/ourteam' : '/ourteam'}
              colorBlack={router.asPath !== '/'}
              className={navbar ? 'active' : ''}
            >
              {t('menu-ourteam')}
            </S.ListItem>
          </S.List>
          <S.ButtonWrapper>
            <S.NavLogin>
              <S.Navigation
                colorBlack={router.asPath !== '/'}
                rel="noopener"
                target="_blank"
                href="https://i4cast.i4sea.com/login"
                className={navbar ? 'active' : ''}
              >
                Login
              </S.Navigation>
            </S.NavLogin>
            {getWidth < 720 ? (
              <a href={formDemoLink} target="_blank" rel="noreferrer">
                <S.Button className="getdemo">
                  {t('menu-get-trial-mobile')}
                </S.Button>
              </a>
            ) : (
              <a href={formDemoLink} target="_blank" rel="noreferrer">
                <S.Button className="getdemo">{t('menu-get-trial')}</S.Button>
              </a>
            )}
            <Link
              href={
                router.query && (router.query.slug || router.query.category)
                  ? '/blog'
                  : router.asPath
              }
              locale={router.locale === 'en-us' ? 'pt-br' : 'en-us'}
            >
              <S.Language
                colorBlack={router.asPath !== '/'}
                className={navbar ? 'active' : ''}
              >
                {t('menu-change-locale')}
              </S.Language>
            </Link>
            <S.BurgerMenuWrapper onClick={() => setIsOpen(true)}>
              <Image
                src={handleImageBurguer(navbar)}
                width={32}
                height={32}
                loading="eager"
                alt="Menu Burger"
              />
            </S.BurgerMenuWrapper>
          </S.ButtonWrapper>
        </S.Content>
      </S.Wrapper>

      {isOpen ? <BurgerMenu onClose={() => setIsOpen(false)} /> : <></>}
    </>
  )
}

export default Menu
