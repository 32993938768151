import styled from 'styled-components'

interface ListItemProps {
  size?: string
}

interface ListProps {
  gap?: string
  marginTop?: string
}

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      0deg,
      #0b63af 0.05%,
      #147cc0 18.93%,
      #1894d2 37.92%,
      #1fa6df 55.21%,
      #23b2e7 70.02%,
      #25b6ea 80.63%
    ),
    #ffffff;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > a {
    position: absolute;
    bottom: 3.2rem;
  }
`

export const BurgerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 2.4rem;
  margin-bottom: 2rem;
`

export const Language = styled.button`
  margin-left: -2.4rem;
  padding: 0 2rem;
  height: 4rem;
  margin-left: 0rem;
  letter-spacing: 0.14rem;
  background: transparent;
  border-radius: 0.8rem;
  border: 1px solid rgba(255, 255, 255, 0.32);
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
`

export const CloseButton = styled.button`
  width: 3.2rem;
  height: 3.2rem;
  background: #fff;
  border: 0;
  outline: 0;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const List = styled.ul<ListProps>`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => (props.gap ? props.gap : '3rem')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '4rem')};
`

export const ListItem = styled.a<ListItemProps>`
  font-size: ${(props) => (props.size ? props.size : '1.6rem')};
  font-weight: 500;
  letter-spacing: 0.16rem;
  color: #fff;
`

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
`

export const NavLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Navigation = styled.a`
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  font-size: 1.6rem;
  letter-spacing: 0.16rem;
  text-transform: uppercase;
  color: #fff;
`

export const Button = styled.button`
  height: 4.8rem;
  letter-spacing: 0.16rem;
  background: transparent;
  border-radius: 0.8rem;
  border: 2px solid #ffffff;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;

  width: 29.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
`
